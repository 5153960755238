 <template>
  <div class="hello">
    <!-- <button @click="begin">开始</button>
    <button @click="animateBtn">开启动画</button>
    <button @click="loop">重复动画</button>
    <button @click="quiz">手写</button>
    {{props.zhi}} -->
    <div style="border: 1px solid #DDD;text-align: center;height: 70px;font-size: 50px;" v-show="this.leis=='A'">{{this.leis=="A"?this.yin:""}}</div>
    <!-- <div id="box" style="width: 250px;height: 250px" v-if="this.leis=='B'">
    </div><div>
<svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" id="box" v-if="this.leis=='A'">
  <line x1="0" y1="0" x2="250" y2="250" stroke="#DDD" />
  <line x1="250" y1="0" x2="0" y2="250" stroke="#DDD" />
  <line x1="125" y1="0" x2="125" y2="250" stroke="#DDD" />
  <line x1="0" y1="125" x2="250" y2="125" stroke="#DDD" />
</svg>
    </div> -->
    <div id="box" style="width: 250px;height: 250px" >
  </div></div>
</template>

<script>
import hanzi from "hanzi-writer";
import {  message } from "ant-design-vue";
export default {
  name: "HelloWorld",
  props: ['zhi',"leis","colors","yin"],
  mounted() {
    console.log(this.zhi)
    console.log(this.leis)
    if(this.leis=="A"&&this.zhi!="")
    {
this.begin()
this.loop();
console.log("执行A")
    }
else if(this.leis=="B"&&this.zhi!=""){
  this.begin()
this.quiz();
console.log("执行B")
}
  },
  data() {
    return {
      }
  },
  methods: {
    begin () {
      console.log(this.zhi+"[begin]");
      this.writer = hanzi.create("box",this.zhi, {
      width: 250,
      height: 250,
      padding: 25,
      showCharacter: false, //不显示汉字
      showOutline: true, //开启动画时，是否显示字
      radicalColor:  this.colors, //"#168F16"// 对偏旁部首着色
      strokeColor: "#000000", //全部着色 这里的颜色只能是16进制的，学英文不显示字
      strokeAnimationSpeed: 0.5, // 5x normal speed 整个动画的速度
      delayBetweenStrokes: 5, //每一笔之间动画的间隔时间，数字越大，间隔时间越长
      onLoadCharDataSuccess: function (data) {
      console.log(data);
        //加载汉字成功的回调
        console.log("加载汉字成功!");
      },
      onLoadCharDataError: function (reason) {
        // 加载汉字失败的回调
        console.log("加载失败！:(");
      },
    })
    },
    animateBtn() {
      this.writer.animateCharacter({
        onComplete: function () {
          //动画结束的回调
          console.log("动画结束");
        },
      });
    },
    loop() {
      this.writer.loopCharacterAnimation();
    },
    quiz() {
      // console.log(props.zhi);
      //       console.log(props.zhi);
      //开启手写，写一笔，显示一笔
      this.writer.quiz({
        //书写错误回调
        onMistake: function(strokeData) {
      var strokeNum=strokeData.strokeNum+1;    
      message.error("你这一笔写错了哦×");
    console.log("第"+strokeNum+'笔：你这一笔写错了哦' );
    console.log('你在这一笔上犯了' + strokeData.mistakesOnStroke + '次错误');
    console.log("【1】" + strokeData.mistakesOnStroke + "【2】" +strokeData.mistakesOnStroke+"【3】" +strokeData.totalMistakes+"[4]"+strokeData.strokesRemaining);
  },
  //书写正确回调
  onCorrectStroke: function(strokeData) {
    var strokeNum=strokeData.strokeNum+1;
    message.success("☀你这一笔写对了！非常好！☀");
    console.log("第"+strokeNum+'笔：写对了非常好！');
    console.log('你在这一笔上犯了' + strokeData.mistakesOnStroke + '次错误-2');
    console.log('还有' + strokeData.strokesRemaining + '笔就写完了，加油！');
  },
  // 书写完毕回调
  onComplete: function(summaryData) {
    message.success('☀☀非常好！“' + summaryData.character+'”字写完了~恭喜!恭喜！☀☀');
    console.log('非常好！你把“' + summaryData.character+"”字写完了~恭喜恭喜！");
    console.log('你有'+summaryData.totalMistakes+"次错误，下次努力一次完成哦");//一共错误的次数
  }
});
    },
  },
  setup(props) {

const doSomething = () => {
            console.log('已触发组件方法');
                    setTimeout(function() {
         this.begin
        }, 700)
            
        }
        doSomething ();
    return {
      props,doSomething
    };
  },
};
</script>

<style scoped>
#box {
  border: 1px solid #ccc;
  width: 250px;
  height: 250px;
  background-image: url('../../assets/zi.gif');
}
/* background-image: url('../assets/LVuser/bgLog.png'); */
</style>

