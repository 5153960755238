

import { defineComponent, onMounted, ref, reactive } from "vue";
import { Modal, message,Progress,RadioButton,RadioGroup } from "ant-design-vue";
import { DateMinute,SecondToTime } from "@/store/formatTime";
import request from "@/api/http";
import router from "@/router";
import bisun1 from "@/components/niu/bisun1.vue";
import { data1,data2,characterClass } from "@/store/characterData";
import { LevelLine} from "@/store/purProcess";
import iGuidelines from "@/components/system/Guidelines.vue";
import { SmileOutlined,MehOutlined,FrownOutlined,CaretRightOutlined,PauseOutlined ,RollbackOutlined,LoadingOutlined} from '@ant-design/icons-vue';
interface CharacterOne{
  w: string;
  f: string;
  s:string;
}
interface CharacterClass{
  number: number;
  name: string;
}
interface CharacterTopics{
  qus: string;
  ans: string;
  sheng:string;
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
  parses: string;
}

export default defineComponent({
  name: "Psychotheraphy",
  components: {
    "a-progress": Progress,
        "v-bisun1":bisun1,
    "a-radio-button": RadioButton,
    "a-radio-group": RadioGroup,
    "iGuidelines":iGuidelines,
    SmileOutlined,MehOutlined,FrownOutlined,CaretRightOutlined,PauseOutlined,RollbackOutlined,LoadingOutlined
  },
  setup() {
    const HanziWriter = require('hanzi-writer');

const topicSendDto = reactive({class1:"12",class2:[] as string[],class3:"全部",class4:"正序",class5:"正常选项",ans:"",id:""});
const topicsDto = ref<CharacterTopics>();
const characterClassdate = ref<CharacterClass[]>([]);
  let listB = ref<CharacterOne[]>([]);
  let listOut = ref<CharacterTopics[]>([]);
// const HanziWriter = require('hanzi-writer');
// const writer = HanziWriter.create('character-target-div', '我', {
//   width: 100,
//   height: 100,
//   padding: 5
// });
const topicsNewsDto2 = ref<any>();
  let guestId: any = JSON.parse(window.localStorage.getItem('GuestId')!);
  const state = reactive({
      fenTrue: 0,
      fenFalse: 0,
      countLong: 0,
      countList: 0,
      ans:"",
      url:"",
      count:0,
      bctAudio:"",
      bctAudio2:"",
      color:"",
      truegif:false,
      nextButton: false,
      niuUrl:0,
      time:2,
      yin:"",
      max:20,
      ccg:[] as string[],
    });
const handleClass2= () => {
      topicSendDto.class2=[];
      
}
const handleClass3= () => {
      topicSendDto.class3="全部";
      //console.log(topicSendDto.class2)
}

let routerId = router.currentRoute.value.params.id;

    const refreshKey = ref(0);
        const audioRef2 = ref();
    const playAudio = (sheng:string,w:string,f:string) => {
      state.bctAudio=f;
      state.ans=w;
      refreshKey.value += 1;
      state.bctAudio2="https://www.aowok.com/upload/video/yin/"+sheng+".mp3";
              setTimeout(function () {
audioRef2.value.play();
  }, 1000)
    }
     const  uplian = () => {
     refreshKey.value += 1;
                   setTimeout(function () {
audioRef2.value.play();
  }, 1000)
    }
    const onS=(id:string) =>{
      state.fenTrue=0;
      state.fenFalse=0;
      state.countLong=0;
      state.countList=0;
      state.ans="";
      state.count=0;
      state.bctAudio="";
      state.bctAudio2="";
      state.color="";
      state.truegif=false;
      state.nextButton= false;
      state.niuUrl=0;
      state.time=2;
      if(id="0"){
        state.ccg=routerId.toString().split("&");
        state.url=routerId.toString();
      }
      else{
        //console.log("shuaxin")
        state.ccg=id.split("&");
      }
      state.max=parseInt(state.ccg[1]);
      topicSendDto.class1=state.ccg[0];
      var ccb=state.ccg.length<3||state.ccg[2]==""?['0']:state.ccg[2].split("a");
      state.url
      listB=data2(parseInt(state.ccg[0]),ccb);
     setTimeout(function () {
      
       }, 500)
      
      
        setTimeout(function () {
  playAudio(listB.value[0].s,listB.value[0].w,listB.value[0].f) 
  }, 1000)
      console.log(listB)
    }
onS("0");
const copyToClipboard = () => {
    const  urls= ref<number[]>([]);
if(topicSendDto.class2.length>0)
{
  for(let i = 0; i < topicSendDto.class2.length; i ++){
    urls.value.push(parseInt(topicSendDto.class2[i].split("(")[0])+10)
   }
}
state.url=topicSendDto.class1+"&"+state.max+"&"+urls.value.join("a");
var text=window.location.protocol+"//"+window.location.host+"/"+ state.url;
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
       message.success('已复制到剪贴板(并刷新题目)！请保存访问路径，在下次访问时使用')
      })
      .catch(() => {
        message.success('复制失败')
      })
  } else {
    // 创建一个textarea元素
    const textarea = document.createElement('textarea')
    // 设置textarea的值为要复制的文本
    textarea.value = text;
    // 将textarea添加到文档中
    document.body.appendChild(textarea)
    // 选中textarea中的文本
    textarea.select()
    try {
      // 尝试复制选中的文本
      const successful = document.execCommand('copy')
      successful ? message.success('复制成功') : message.success('复制失败')
    } catch (err) {
      //console.log('不能使用这种方法复制', err)
    }
    // 将textarea从文档中移除
    document.body.removeChild(textarea)
  }
  listOut.value.length=0;
  onS(state.url+"&chinesecharacter");
}
    return {
      DateMinute,copyToClipboard,
      listOut,listB,refreshKey,uplian,
      labelCol: { style: { width: "80px" } },audioRef2,playAudio,
      wrapperCol: { span: 14 },state,handleClass3,handleClass2,onS,data1,data2,characterClass,
      SecondToTime,topicSendDto,topicsNewsDto2,topicsDto,LevelLine
    };
  },
});
